// Primary

export const red = "#FA403C";
export const blue = "#3B98F2";
export const goBlue = "#1999d5";
// Greys

export const greyDark = "#212529";
export const grey = "#58626D";
export const greyLight = "#9DA6B0";
export const greyLightest = "#E0E3E7";
export const offWhite = "#F3F4F7";

// Misc

export const white = "#fff";
export const black = "#000";
export const yellow = "#FAB613";
export const green = "#15CD96";

// Set colors

export const primaryColor = "#1b1b1b";
export const secondaryColor = blue;
export const backgroundColor = "#EBEDEE";
export const backgroundGradient =
  "linear-gradient(62deg, #EBEDEE 0%, #FBFBFB 100%)";
export const borderColor = greyLightest;
