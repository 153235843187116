import React from "react";
import { TopMenu } from "../react-fsc-components/src/components";
import { primaryColor } from "../styles/colors";
import styled from "styled-components";
import { smallFontSize } from "../styles/typography";

const TopMenuWrapper = styled.div`
  position: fixed;
  top: ${document.getElementById("wpadminbar") ? "32px" : 0};
  left: 0;
  width: 100%;
  z-index: 999;
  & svg {
    color: white;
  }
`;

const LogoWrapper = styled.a`
  height: 100%;
  width: 250px;
  padding: 12px 0;
  outline: none;
  display: block;
  & > img {
    max-height: 100%;
    max-width: 100%;
  }
`;

const MenuItems = styled.div`
  color: white;
  display: flex;
  & > div {
    margin-left: 12px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MenuItem = styled.a`
  margin-left: 24px;
  font-weight: light;
  font-size: ${smallFontSize}
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Header = () => (
  <TopMenuWrapper id={"main-menu"}>
    <TopMenu
      logo={
        <LogoWrapper href={window.siteInfo.home}>
          <img
src={window.siteInfo.logo}
alt={"Logo"} />
        </LogoWrapper>
      }
      minHeight={"70px"}
      height={"10vh"}
      bgColor={primaryColor}
    >
      <MenuItems>
        {window.siteInfo.mainMenu.map(item => (
          <MenuItem
            href={item.url}
            onClick={() => {
              if (window.innerWidth < 768)
                document.getElementById("MobileMenuIcon").parentElement.click();
            }}
          >
            {item.title}
          </MenuItem>
        ))}
        {window.siteInfo.languages.map(lang => {
          if (lang.code === window.siteInfo.currentLang) return;

          return <MenuItem href={lang.url}>{lang.code.toUpperCase()}</MenuItem>;
        })}
      </MenuItems>
    </TopMenu>
  </TopMenuWrapper>
);

export default Header;
