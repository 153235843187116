import React, { Component } from "react";
import Layout from "./layouts/Layout";

import Page from "./layouts/Page";

class App extends Component {
  componentDidMount() {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener("click", function(e) {
        e.preventDefault();

        window.scrollTo({
          top:
            document.querySelector(this.getAttribute("href")).offsetTop -
            document
              .getElementById("topMenuBar")
              .firstChild.getBoundingClientRect().height,
          behavior: "smooth"
        });
      });
    });
  }

  render() {
    return (
      <>
        <Layout>
          {!window.page ? "Please select a page as home" : <Page />}
        </Layout>
      </>
    );
  }
}

export default App;
