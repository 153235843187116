import React from "react";
import styled from 'styled-components';
import { black, red, white } from "../styles/colors";
import { Button } from "../react-fsc-components/src/components";
import { useEffect } from "react";
import { boxShadow } from "../styles/global";
import { cookieKey, deleteAllCookies, hasAcceptedCookies } from "./CookieSettings";

const Wrapper = styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);

    svg {
        height: 12px;
        width: 12px;
        cursor: pointer;
    }

    > div {
        border-radius: 10px;
        padding: 20px;
        background-color: ${white};

       ${boxShadow}
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 450px;
        text-align: center;

        > div:first-child{
            margin-bottom: 10px;
        } 

        p {
            font-size: 14px;
            a {
                color: white;
                text-decoration: underline;
            }
        }

        > div:nth-child(2) {
            font-size: 18px;
            font-weight: bold;
            max-width: 250px;
        }

        > div:last-child {
            display: flex;
            flex-direction: column;
            align-items: center;

            >div{
                margin-top: 5px;
            }

        }
    }
`

export default function(props){
    useEffect(() => {
        if(localStorage.getItem(cookieKey) === "1"){
            hasAcceptedCookies();
        }else{
            deleteAllCookies()
        }
    });

    if(!props.show)
        return <></>

    return(
        <Wrapper>
            <div>
                <div onClick={() => {
                    if(localStorage.getItem(cookieKey) === null) return;
                    props.close()
                }}>
                    {localStorage.getItem(cookieKey) !== null && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.43 13.43">
                        <polygon fill="currentColor" points="13.43 1.41 12.02 0 6.72 5.3 1.41 0 0 1.41 5.3 6.72 0 12.02 1.41 13.43 6.72 8.13 12.02 13.43 13.43 12.02 8.13 6.72 13.43 1.41" />
                    </svg>}
                </div>

                <div>
                    {window.siteInfo.currentLang === "fr"
                        ? `Ce site web utilise des fichiers témoins`
                        : `This website uses cookies`}
                </div>

                <p style={{margin: window.siteInfo.currentLang === "fr" ? "12px 0" : undefined}}>
                    {window.siteInfo.currentLang === "fr" 
                        ? `En cliquant sur « Autoriser tous les fichier témoins », vous acceptez le stockage de fichiers témoins sur votre appareil pour améliorer la navigation sur le site et en analyser l'utilisation.`
                        : `By clicking "Accept All Cookies", you agree to the storing of cookies on your device to enhance site navigation and analyze site usage.`}
                
                    <br />
                    <a href={ window.siteInfo.currentLang === "fr" ? "/fr/politique-concernant-les-fichiers-temoins" : "/cookie-policy"}>
                    {window.siteInfo.currentLang === "fr" 
                        ? `Politique concernant les fichiers témoins`
                        : `Cookie Policy`}.</a>
                </p>

                <div>
                    <Button small secondary onClick={props.openSettings} btnColor={black} color={white}>
                        {window.siteInfo.currentLang === "fr" 
                            ? `Paramètres des fichiers témoins`
                            : `Cookie Settings`}
                    </Button>    
                    <Button small secondary btnColor={black} onClick={() => {
                        localStorage.setItem(cookieKey, "0");
                        deleteAllCookies()
                        props.close();
                        const url = new URL(window.location.href);
                        if(url.hostname.startsWith("www")){
                            url.searchParams.append("r", url.toString());
                            url.hostname = url.hostname.slice("www.".length);
                        }
                        url.searchParams.append("reject_cookies", "1");
                        window.location.href = url.toString();
                    }} color={white}>
                        {window.siteInfo.currentLang === "fr" 
                            ? `Tout refuser`
                            : `Reject All`}
                    </Button>
                    <Button small btnColor={black} onClick={() => {
                        localStorage.setItem(cookieKey, "1");
                        hasAcceptedCookies();
                        props.close();
                    }} color={white}>
                        {window.siteInfo.currentLang === "fr" 
                            ? (<>Autoriser tous<br/>les fichiers témoins</>)
                            : `Accept All Cookies`}
                    </Button>
                </div>
            </div>
        </Wrapper>
    )
}