import { css, createGlobalStyle } from "styled-components";
import { rgba } from "polished";
import Normalize from "./normalize";
import { backgroundColor, backgroundGradient, black } from "./colors";

import MalloryLight from "../static/fonts/Mallory-Light.woff";
import MalloryBook from "../static/fonts/Mallory-Book.woff";
import MalloryMedium from "../static/fonts/Mallory-Medium.woff";
import MalloryBold from "../static/fonts/Mallory-Bold.woff";

// Animation
export const animationTime = "0.3s";
export const animationCurve = "cubic-bezier(0.785, 0.135, 0.150, 0.860)";

// Border Radius
export const borderRadius = "4px";

// Misc
export const headerHeight = "56px";
export const tabBarHeight = "56px";
export const tabNavHeight = "48px";
export const containerMaxWidth = "504px";

// Inputs
export const inputHeight = "50px";
export const inputHeightSmall = "34px";

// Square
export const square = size => css`
  width: ${size};
  height: ${size};
`;

// Box shadow
export const boxShadow = `box-shadow: 0 2px 5px 0 ${rgba(black, 0.08)};`;
export const reverseBoxShadow = `box-shadow: 0 -2px 6px 0 ${rgba(
  black,
  0.04
)};`;

// Cover
export function cover(position) {
  return `
    position: ${position};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `;
}

// Styles
export const GlobalStyle = createGlobalStyle`
  ${Normalize}
  
  @font-face {
    font-family: 'mallory-microplus';
    src: url('${MalloryLight}') format('woff');
    font-weight: light;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'mallory-microplus';
    src: url('${MalloryBook}') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'mallory-microplus';
    src: url('${MalloryMedium}') format('woff');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'mallory-microplus';
    src: url('${MalloryBold}') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  html, body {
    background-color: ${backgroundColor};
    background: ${backgroundGradient};
    min-height: 100vh;
  }
`;
