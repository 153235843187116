import React from "react";
import styled from "styled-components";

import { GlobalStyle } from "../styles/global";
import { GlobalTypoStyle } from "../styles/typography";
import * as spacing from "../styles/spacing";

import Header from "../components/Header";
import Footer from "../components/Footer";

const Container = styled.div`
  max-width: 980px;
  padding: ${spacing.large};
  margin: 10vh auto 0;
`;

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    <GlobalTypoStyle />
    <Header />
    <Container>
      {children}
      <Footer />
    </Container>
  </>
);

export default Layout;
