import React, { useState } from "react";
import styled from "styled-components";
import CookiePopup from "./CookiePopup";
import CookieSettings from "./CookieSettings";

const Footer = styled.footer`
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    padding-top: 30px;
    a {
        text-decoration: underline;
        margin-left: 10px;
    }
    @media screen and (max-width: ${window.siteInfo.currentLang === "fr" ? "4000px" : "940px"}) {
        flex-direction: column;
        > div:first-child {
            margin-bottom: 10px;
        }
        a {
            margin-left: 0px;
            margin-right: 10px;
        }
    }

    @media screen and (max-width: 700px) {
        a {
            display: block;
            margin-top: 10px;
        }
    }
`

export default function () {
    const [showCookiePopup, setShowCookiePopup] = useState(localStorage.getItem("cookies-settings") === null);
    const [showCookieSettings, setShowCookieSettings] = useState(false);

    return <>
        <CookiePopup
            show={showCookiePopup}
            close={() => setShowCookiePopup(false)}
            openSettings={() => {
                setShowCookiePopup(false);
                setShowCookieSettings(true);
            }}
        />
        <CookieSettings show={showCookieSettings} close={() => setShowCookieSettings(false)} />
        <Footer>
            <div dangerouslySetInnerHTML={{ __html: window.siteInfo.footer.credits }} />
            <div>
                {window.siteInfo.footer.creditsLinks.match(/<a.*?\/a>/g).map(link =>
                    <span dangerouslySetInnerHTML={{ __html: link }} />)}
                <a href="#" onClick={(e) => {
                    e.preventDefault()
                    setShowCookieSettings(true);
                }}>
                    {window.siteInfo.currentLang === "fr" ? "Paramètres des fichiers témoins" : "Cookies Settings"}
                </a>
            </div>

        </Footer>
    </>
}