import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { black, white } from "../styles/colors";
import { Button } from "../react-fsc-components/src/components";
import TagManager from 'react-gtm-module';
import { boxShadow } from "../styles/global";

const Wrapper = styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    padding-top: 110px;
    overflow: auto;

    svg {
        height: 12px;
        width: 12px;
        cursor: pointer;
    }

    > div {
        background-color: ${white};
        color: ${black};
        padding: 30px;
        margin-top: 20px;

       ${boxShadow}
        
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        max-width: 700px;

        border-radius: 20px;

        > div:first-child{
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-end;
            width: 100%;
        } 

        p {
            font-size: 14px;
            a {
                color: inherit;
                text-decoration: underline;
            }
            margin: 10px 0;
        }

        > div:nth-child(4) {
            margin-bottom: 20px;
        }

        > div:nth-child(2),
        > div:nth-child(5) {
            font-size: 24px;
            font-weight: bold;
        }

        > .cookie-type {
            svg {
                transform: rotate(-90deg);
                transition: 0.3s all;
            }
            > div:first-child {
                display: flex;
                justify-content: space-between;
            }
            > div:last-child {
                max-height: 0;
                overflow: hidden;
                transition: 0.3s all;
            }
            padding: 20px 0 10px;
            border-bottom: 2px solid ${black};

            &.expanded {
                svg {
                    transform: rotate(0deg);
                }
                > div:last-child {
                    max-height: 300px;
                }
            }
        }

        > div:last-child {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            margin-top: 20px;

            >div{
                margin-left: 5px;
            }

        }
    }
`;

export const cookieKey = "cookies-settings"

export function deleteAllCookies() {
    var cookies = document.cookie.split(";");
    const url = new URL(window.location.href);
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie;
        document.cookie = name + "= ; expires=Thu, 01 Jan 1970 00:00:00 GMT;";
        document.cookie = name + `= ; path=/; domain=.${url.hostname}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
        document.cookie = name + `= ; path=${url.pathname}; domain=.${url.hostname}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
        if(url.hostname.includes("www")){
            document.cookie = name + `= ; path=/; domain=.${url.hostname.slice("www.".length)}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
            document.cookie = name + `= ; path=${url.pathname}; domain=.${url.hostname.slice("www.".length)}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
        }
    }
    url.search = "";
    window.history.replaceState(null, null, url.toString());
};

export const hasAcceptedCookies = () => {
    TagManager.initialize({ gtmId: 'G-W4R12VP2EN' });
}


export default function (props) {
    if (!props.show)
        return <></>

    const rejects = () => {
        localStorage.setItem(cookieKey, "0");
        deleteAllCookies()
        props.close();
        const url = new URL(window.location.href);
        if(url.hostname.startsWith("www")){
            url.searchParams.append("r", url.toString());
            url.hostname = url.hostname.slice("www.".length);
        }
        url.searchParams.append("reject_cookies", "1");
        window.location.href = url.toString();
    }

    const accepts = () => {
        localStorage.setItem(cookieKey, "1");
        hasAcceptedCookies();
        props.close();
    }

    return (
        <Wrapper>
            <div>
                <div onClick={props.close}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.43 13.43">
                        <polygon fill="currentColor" points="13.43 1.41 12.02 0 6.72 5.3 1.41 0 0 1.41 5.3 6.72 0 12.02 1.41 13.43 6.72 8.13 12.02 13.43 13.43 12.02 8.13 6.72 13.43 1.41" />
                    </svg>
                </div>

                <div>
                    {window.cookieSettings.title}
                </div>

                <div dangerouslySetInnerHTML={{ __html: window.cookieSettings.introduction }} />

                <Button 
                    small={true} 
                    color={white} 
                    btnColor={black} 
                    secondary={false} 
                    onClick={accepts}
                >
                    {window.cookieSettings.accept_all_label}
                </Button>

                <div>{window.cookieSettings.manage_label || "Manage Consent Preferences"}</div>

                {window.cookieSettings.cookie_types.map((cookieType, i) => <div className="cookie-type">
                    <div>
                        <span onClick={e => {e.currentTarget.parentElement.parentElement.classList.toggle("expanded")}}>
                            <Arrow /> {cookieType.title}
                        </span>
                        {i === 0 
                            ? <div style={{fontSize: "small", fontWeight: "bold"}}>{window.siteInfo.currentLang === "fr" ? "Toujours actif" : "Always Active"}</div>
                            : <Switch 
                                active={i === 0 || window.localStorage.getItem(cookieKey) === "1"} 
                                disabled={i === 0}
                                didChange={(value) => {
                                    cookieType.accepts = value;
                                }}
                            />}
                    </div>
                    <div dangerouslySetInnerHTML={{__html: cookieType.description}} />
                </div>)}

                <div>
                    <Button small secondary btnColor={black} 
                        onClick={rejects} 
                        color={white}>
                        {window.cookieSettings.reject_all_label}
                    </Button>
                    <Button small secondary btnColor={black} onClick={() => {
                        const acceptsCookie = (window.cookieSettings.cookie_types).find(({ accepts }, index) => index !== 0 && accepts);
                        if(acceptsCookie) {
                            accepts()
                        } else {
                            rejects()
                        }

                    }} color={white}>
                        {window.cookieSettings.confirm_label}
                    </Button>
                </div>
            </div>
        </Wrapper>
    )
}



const Arrow = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.82 5.82">
  <polyline stroke={black} strokeWidth={2} fill="none" points="8.11 .71 4.41 4.41 .71 .71"/>
</svg>


const SwitchContainer = styled.div`
    border: 2px solid ${black};
    border-radius: 30px;
    height: 20px;
    width: 40px;
    background-color: ${props => props.active ? black : white};
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: 0.3s all;

    & > div:first-child {
        position: relative;
        background-color: ${props => props.active ? white : black};
        height: calc(100% - 2px);
        border-radius: 50%;
        aspect-ratio: 1/1;
        left: ${props => props.active ? "calc(100% - 1px)" : "1px"};
        transform: ${props => props.active ? "translate(-100%)" : "unset"};
        transition: 0.3s all;
    }

    & > div:nth-child(2),
    & > div:last-child {
        position: absolute;
        top: 0;
        transform: translateY(-120%);
        font-size: 10px;
        font-weight: bold;

        &:nth-child(2) {
            right: -10px;
        }
        &:last-child {
            left: -10px;
        }
    }
`


const Switch = (props) => {
    const [active, setActive] = useState(props.active);

    useEffect(() => props.didChange(active), [active]);

    return <SwitchContainer active={active} style={props.disabled ? {opacity: 0.5} : {}} onClick={() => {
        if(props.disabled) 
            return;
        setActive(!active)
    }}>
        <div />
        <div>{window.cookieSettings.on_label}</div>
        <div>{window.cookieSettings.off_label}</div>
    </SwitchContainer>
}