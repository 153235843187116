import React from 'react';
import ReactDOM from 'react-dom';
import styled from "styled-components";

const placeholderColor = "#add8e6";

export const TopMenu = ({logo, bgColor, minHeight, height, children} = {
    logo : "Your Logo",
    bgColor : placeholderColor,
    minHeight: "70px",
    height: "10vh",
    children : ["Item 1", "Item2", "Item 3"].map((item,i) => (<div key={i} style={{marginLeft:12}}>{item}</div>))
}) => {

    let Wrapper = styled.div`
        width:100%;
        min-height: ${minHeight};
        height: ${height};
        background-color: ${bgColor};
        position: relative;
        transition: 0.3s height;
    `;

    let Inner = styled.div`
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        box-sizing: border-box;
        max-height: 10vh;
        min-height: 70px;
    `;

    let Logo = styled.div`
        height: 100%;
    `;
    let Items = styled.div`
        display: flex;
        @media (max-width: 768px) {
            display:none;
        }
    `;

    let Mobile = styled.div`
        height: 100%;
        width: 32px;
        display: none;
        & > div{
            height: 100%;
            width: 100%;
            padding: 6px;
            box-sizing: border-box;
        }
        & > div > svg{
            height: 100%;
            width: 100%;
        }
        &:hover{
            cursor: pointer;
        }
        @media (max-width: 768px) {
            display:flex;
        }
    `;

    let MobileMenu = styled.div`
        opacity: 0;
        position: absolute;
        width: 90%;
        text-align:center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s opacity;
        display: none;
        font-size: 42px;
        & a{
            margin: 3px 0;
        }
    `;
    let MobileItems = styled.div`
        
    `;

    let toggleMobileMenu = () => {
        if(document.getElementById('topMenuBar').style.height === "100vh"){
            document.getElementById('topMenuBar').style.height = "10vh";
            document.getElementById('MobileMenu').style.opacity = "0";
            document.getElementById('MobileMenu').style.display = "none";
            ReactDOM.render(
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars"
                     className="svg-inline--fa fa-bars fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 448 512">
                    <path fill="currentColor"
                          d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"/>
                </svg>
            , document.getElementById('MobileMenuIcon'))
        }
        else {
            document.getElementById('topMenuBar').style.height = "100vh";
            document.getElementById('MobileMenu').style.display = "block";
            setTimeout(() => {
                document.getElementById('MobileMenu').style.opacity = "1"
            }, 310);
            ReactDOM.render(
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 354.44 354.44">
                    <path fill="currentColor" d="M274.91,256,396.53,134.38a16,16,0,0,0,0-22.63L368.25,83.47a16,16,0,0,0-22.63,0L224,205.09,102.38,83.47a16,16,0,0,0-22.63,0L51.47,111.75a16,16,0,0,0,0,22.63L173.09,256,51.47,377.62a16,16,0,0,0,0,22.63l28.28,28.28a16,16,0,0,0,22.63,0L224,306.91,345.62,428.53a16,16,0,0,0,22.63,0l28.28-28.28a16,16,0,0,0,0-22.63Z" transform="translate(-46.78 -78.78)"/>
                </svg>
                , document.getElementById('MobileMenuIcon'))
        }
    };


    return(
        <Wrapper id={"topMenuBar"}>
            <Inner>
                <Logo>{logo}</Logo>
                <Items>
                    {
                        children
                    }
                </Items>
                <Mobile onClick={toggleMobileMenu}>
                    <div id={"MobileMenuIcon"}>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars"
                             className="svg-inline--fa fa-bars fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 448 512">
                            <path fill="currentColor"
                                  d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"/>
                        </svg>
                    </div>
                </Mobile>
            </Inner>
            <MobileMenu id={"MobileMenu"}>
                <MobileItems>
                    {
                        children
                    }
                </MobileItems>
            </MobileMenu>
        </Wrapper>
    )
};

const LightenDarkenColor = (col, amt) => {
    // method found here https://css-tricks.com/snippets/javascript/lighten-darken-color/
    let usePound = false;

    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }

    let num = parseInt(col,16);

    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if  (r < 0) r = 0;

    let b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if  (b < 0) b = 0;

    let g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
};

export const Button = ({children, color, btnColor, secondary, onClick} = {
    children: "Click Here",
    color: "white",
    btnColor : placeholderColor,
    secondary : false,
    onClick: () => alert("Clicked!"),
}) => {
    let Wrapper = styled.div`
        padding: 12px 32px;
        box-sizing:border-box;
        background-color: ${(secondary)?"":btnColor};
        color: ${(secondary)?btnColor:color};
        border: 2px solid ${btnColor};
        border-radius: 200px;
        display: inline-block;
        &:hover{
            cursor:pointer;
            background-color:${(secondary)?btnColor:LightenDarkenColor(btnColor, -20)};
            border-color:${LightenDarkenColor(btnColor, -20)};
            color:${color}
        }
    `;

    return (
        <Wrapper className={"btn " + (secondary?"secondary":"")} onClick={onClick} >
            {
                children
            }
        </Wrapper>
    )
};

export const InputText = ({label} = {
    label: "Label"
}) => {

    let Wrapper = styled.div`
    
    `;

    let Label = styled.div`
        margin-bottom: 3px
    `;

    let Input = styled.input`
        width: 100%;
        padding: 6px;
        border-radius: 3px;
        border: 1px solid currentColor;
        outline: 0;
    `;

    return (
        <Wrapper>
            <Label>{label}</Label>
            <Input type={"text"}/>
        </Wrapper>
    )
};

export const WantedPoster =
    ({
         name, nameFontSize, clause, clauseFontSize,
         sides, sidesFontSize, img, hoverImg, bg
    } = {
        name : "Full Name",
        nameFontSize : "22px",
        clause : "For Something",
        clauseFontSize : "22px",
        sides : "Wanted",
        sidesFontSize: "48px",
        img: "img",
        hoverImg: "hoverImg",
        bg : placeholderColor
}) => {
    let Wrapper = styled.div`
        user-select: none;
        opacity: 0;
        width:100%;
        padding-top: 120%;
        position: relative;
        display:flex;
        flex-direction: column;
        align-items: center;
        background-color: ${bg}
        overflow: hidden;
        transition: 0.3s opacity;
        &:hover .topImg{
            opacity: 0;
        }
    `;
    let Inner = styled.div`
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    `;
    let Top = styled.div`
        display: flex;
        width: 100%;
        min-height: 70px;
        align-items:center;
    `;
    let Mid = styled.div`
        display: flex;
        width: 100%;
        height: calc(100% - 140px);
        align-items:center;
        justify-content: space-evenly;
    `;
    let Icon = styled.div`
        min-width:70px
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        & > svg{
            height: 32px;
            width: 32px;
        }
    `;
    let Title = styled.div`
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: ${nameFontSize};
        line-height: ${nameFontSize};
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: center;
    `;
    let Clause = styled.div`
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: ${clauseFontSize};
        line-height: ${clauseFontSize};
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: center;
    `;
    let WantedLeft = styled.div`
        font-size: ${sidesFontSize};
        letter-spacing: 4px;
        max-width: 70px;
        height: 100%;
        text-transform: uppercase;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(-90deg);
    `;
    let WantedRight = styled.div`
        font-size: ${sidesFontSize};
        letter-spacing: 4px;
        max-width: 70px;
        height: 100%;
        text-transform: uppercase;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(90deg)
    `;
    let Image = styled.div`
        width: calc(100% - 140px);
        height: 100%;
        display: flex;
        justify-content: center;
        background-color: lightgray;
        position: relative;
        & > img {
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
            opacity: 1;
            transition: 0.3s opacity;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        }
    `;
    let Bot = Top;

    const primaryIcon =
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.56 112.03">
            <g>
                <rect x="61.1" y="20.37" width="25.46" height="10.18" fill="#231f20"/>
                <polygon points="0 0 0 10.18 0 30.55 10.18 30.55 10.18 10.18 81.47 10.18 71.29 0 0 0" fill="#231f20"/>
                <polygon points="85.55 71.29 61.1 71.29 61.1 61.1 75.36 61.1 85.55 71.29" fill="#231f20"/>
                <polygon points="0 61.1 0 71.29 20.37 71.29 20.37 81.47 20.37 84.53 20.37 91.66 86.56 91.66 86.56 81.47 30.55 81.47 30.55 71.29 30.55 64.16 30.55 61.1 0 61.1" fill="#231f20"/>
                <path d="M51.43,66.2a5.86,5.86,0,1,1-5.86-5.86,5.86,5.86,0,0,1,5.86,5.86v0" fill="#f32735"/>
                <polygon points="0 81.47 0 101.84 0 112.03 10.18 112.03 30.55 112.03 30.55 101.84 10.18 101.84 10.18 81.47 0 81.47" fill="#231f20"/>
                <polygon points="40.74 101.84 40.74 112.02 75.36 112.02 85.55 101.84 40.74 101.84" fill="#231f20"/>
                <rect y="40.74" width="30.55" height="10.18" fill="#231f20"/>
                <polygon points="50.92 40.74 50.92 30.55 50.92 21.39 50.92 20.37 20.37 20.37 20.37 30.55 40.74 30.55 40.74 40.74 40.74 46.85 40.74 50.92 71.29 50.92 81.47 40.74 50.92 40.74" fill="#231f20"/>
            </g>
        </svg>;

    const secondaryIcon =
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.91 112">
            <g>
                <path d="M28,35.76V28.82l-2.86-2.53H8.9V37.34H26.07ZM24.16,8.3H8.9V18.71H23.21l2.86-2.52V9.87ZM36.88,26.61V38.92l-6.68,6H0V.72H28.3l6.67,6V18.4l-3.5,3.15Z" fill="#231f20"/>
                <path d="M65.41,35V10.83l3.53-3.19H82.41l3.52,3.19V35l-3.52,3.18H68.94ZM64.76,0,56.43,7.64V38.21l8.33,7.65H86.58l8.33-7.65V7.64L86.58,0Z" fill="#231f20"/>
                <polygon points="0 67.08 0 111.31 36.88 111.31 36.88 103.72 36.88 103.72 36.88 93.05 28.18 93.05 28.18 103.72 8.91 103.72 8.91 67.08 0 67.08" fill="#231f20"/>
                <path d="M65.24,101.2V77l3.53-3.18H82.24L85.77,77v24.2l-3.53,3.18H68.77Zm-.64-35-8.34,7.64v30.57L64.6,112H86.41l8.34-7.65V73.81l-8.34-7.64Z" fill="#231f20"/>
            </g>
        </svg>;

    let posterRef = React.createRef();
    let leftSide = React.createRef();
    let rightSide = React.createRef();

    let container = document.createElement('div');
    let testSpan = document.createElement('span');


    const dynamicFontSize = () => {
        let posterHeight = posterRef.current.getBoundingClientRect().height;
        let availableHeight = posterHeight - 70 * 2 - 50;

        let fontSize = sidesFontSize;

        testSpan.style.fontSize = fontSize;
        testSpan.innerText = sides;

        document.body.appendChild(container);
        container.appendChild(testSpan);

        let testWidth = testSpan.getBoundingClientRect().width;

        while(testWidth > availableHeight){
            if(parseInt(fontSize) < 5)
                break;

            fontSize = (parseInt(fontSize) - 1) + "px";
            testSpan.style.fontSize = fontSize;
            testWidth = testSpan.getBoundingClientRect().width;
        }

        testSpan.remove();
        container.remove();

        leftSide.current.style.fontSize = fontSize;
        rightSide.current.style.fontSize = fontSize;
    };

    window.addEventListener('resize', dynamicFontSize);
    window.addEventListener('load', () => {
        dynamicFontSize();
        posterRef.current.style.opacity = 1;
    });


    return (
        <Wrapper ref={posterRef}>
            <Inner>
                <Top>
                    <Icon>{primaryIcon}</Icon>
                    <Title>{name}</Title>
                    <Icon>
                        {
                            secondaryIcon
                        }
                    </Icon>
                </Top>
                <Mid>
                    <WantedLeft ref={leftSide}>{sides}</WantedLeft>
                    <Image>
                        <img src={hoverImg} alt={name} />
                        <img className={"topImg"} src={img} alt={name}/>
                    </Image>
                    <WantedRight ref={rightSide}>{sides}</WantedRight>
                </Mid>
                <Bot>
                    <Icon>
                        {
                            secondaryIcon
                        }
                    </Icon>
                    <Clause>{clause}</Clause>
                    <Icon>
                        {
                            secondaryIcon
                        }
                    </Icon>
                </Bot>
            </Inner>
        </Wrapper>
    )
};